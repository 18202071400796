import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";

export const table = {
  primaryData: [
    {
      title: "Class List",
      value: "class_list",
      type: "configue",
    },
    {
      title: "Program Name",
      value: "program_name",
      type: "configue",
    },
    {
      title: "Match Type",
      value: "match_type",
    },
    {
      title: "Match Id List",
      value: "match_id_list",
      type: "configue",
    },
    // {
    //   title: "program Id",
    //   value: "program_id",
    // },
    {
      title: "Actions",
      width: "10%",
    },
  ],
  secondaryData: [
    {
      title: "Created At",
      value: "created_at",
      type: "configue",
    },
    {
      title: "created By",
      value: "created_username",
      type: "configue",
    },
    {
      title: "Updated At",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
    },
  ],
};

export type createProgramType = {
  class_list: string[];
  match_type: string;
  match_id_list: string[];
  program_id: string;
};

export const createProgramInitialValues: createProgramType = {
  class_list: [],
  match_type: "",
  match_id_list: [],
  program_id: "",
};

export const createProgramMandatoryFields = [
  { id: "class_list" },
  { id: "match_type" },
  { id: "match_id_list" },
  { id: "program_id" },
];

export const searchProgramMandatoryFields = [
  { id: "class_list" },
  { id: "match_type" },
  { id: "match_id_list" },
];

export const createProgramValidationSchema = Yup.object().shape({
  class_list: Yup.array().min(1, "class value required to map program ").required("required to map program "),
  match_id_list: Yup.array()
    .min(1, "match_id_list value required to map program ")
    .required("value required to map program "),
  match_type: Yup.string().required("match_type is required to map program"),
  program_id: Yup.string().required("program_id is required to map program"),
});

export const createProgramProperties = (watch: any) => {
  return [
    {
      id: "class_list",
      type: "multi-select-dependency",
      label: "Class *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      required: true,
      list: "class_course_batch_data",
      keyValue: { label: "class_name", value: "class_id" },
      dependentFields: ["batch_list", "sub_batch_list"],
      dependentFlds: {
        class_course_batch_data: "class_id",
      },
    },
    {
      id: "match_type",
      type: "normal-select",
      label: "Match Type *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "Board", value: "board" },
        { label: "Batch", value: "batch" },
      ],
    },
    {
      id: "match_id_list",
      type: "multi-select-dependency",
      label: "Match Id List *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      isDependent: true,
      options: [],
      list: `${watch?.match_type == "" ? "empty" : watch?.match_type}_list`,
      dependencyLabel: "Please select match_type",
      keyValue:
        watch?.match_type == "board"
          ? { label: "board_name", value: "board_id" }
          : { label: "batch_name", value: "batch_id" },
      fieldKeyLists: {},
    },
  ];
};

export const ProgramProperties = (watch: any) => {
  return [
    {
      id: "program_id",
      type: "single-select-dependency",
      label: "Program *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      required: true,
      isDependent: false,
      list: "program_class_data",
      onlyApiCall: true,
      requestList: async (payload) => {
        //  return {class_id: watch?.class_list};
        return {};
      },
      endpoint: endpointsMd.GET_PROGRAM_LIST,
      keyValue: { label: "program_name", value: "program_id" },
      //dependentFields: ["class_id", "subject_id"],
      dependentFields: [""],
    },
  ];
};

export const updateProgramProperties = (watch: any) => {
  return [
    {
      id: "class_list",
      type: "multi-select-dependency",
      label: "Class *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      required: true,
      list: "class_course_batch_data",
      keyValue: { label: "class_name", value: "class_id" },
      dependentFields: ["batch_list", "sub_batch_list"],
      dependentFlds: {
        class_course_batch_data: "class_id",
      },
    },
    {
      id: "program_id",
      type: "normal-select",
      label: "Program *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      required: true,
      isDependent: false,
      list: "program_class_data",
      onlyApiCall: true,
      requestList: async (payload) => {
        return {};
      },
      endpoint: endpointsMd.GET_PROGRAM_LIST,
      keyValue: { label: "program_name", value: "program_id" },
      //dependentFields: ["class_id", "subject_id"],
      dependentFields: [""],
    },
    {
      id: "match_type",
      type: "normal-select",
      label: "Match Type *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "Board", value: "board" },
        { label: "Batch", value: "batch" },
      ],
    },
    {
      id: "match_id_list",
      type: "multi-select-dependency",
      label: "Match Id List *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      isDependent: true,
      options: [],
      list: `${watch?.match_type == "" ? "empty" : watch?.match_type}_list`,
      dependencyLabel: "Please select match_type",
      keyValue:
        watch?.match_type == "board"
          ? { label: "board_name", value: "board_id" }
          : { label: "batch_name", value: "batch_id" },
      fieldKeyLists: {},
    },
  ];
};
